import { render, staticRenderFns } from "./MPProductItem.vue?vue&type=template&id=3fce38cc"
import script from "./MPProductItem.vue?vue&type=script&lang=js"
export * from "./MPProductItem.vue?vue&type=script&lang=js"
import style0 from "./MPProductItem.vue?vue&type=style&index=0&id=3fce38cc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__handle_cutex6v7bvjfvrrsihidbsse5i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MarketProductMPProductLabels: require('/usr/src/app/components/Market/Product/MPProductLabels.vue').default,MarketProductMPDiscount: require('/usr/src/app/components/Market/Product/MPDiscount.vue').default,MarketProductMPLogo: require('/usr/src/app/components/Market/Product/MPLogo.vue').default,MarketProductsMPChoiceVariationsProduct: require('/usr/src/app/components/Market/Products/MPChoiceVariationsProduct.vue').default,MarketAddToCart: require('/usr/src/app/components/Market/AddToCart.vue').default})
